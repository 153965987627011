export const footerLinks = [
  {
    href: "https://www.gnv.it/it/assistenza/informative/condizioni-generali?_gl=1*d5yrm7*_up*MQ..*_ga*NzU0NjM0MzguMTY4MTcyMjcyMQ..*_ga_171X9PNKC9*MTY4MTcyMjcxOS4xLjAuMTY4MTcyMjcxOS4wLjAuMA..",
    textKey: "footer-info-1",
  },
  {
    href: "https://www.gnv.it/it/assistenza/informative/cookie-policy?_gl=1*lhp1eb*_up*MQ..*_ga*MTQ4MDUxMzY0Ni4xNjgxNzIzMjU1*_ga_171X9PNKC9*MTY4MTcyMzI1Mi4xLjAuMTY4MTcyMzI1Mi4wLjAuMA..",
    textKey: "footer-info-2",
  },
  {
    href: "https://www.gnv.it/it/assistenza/informative/diritti-dei-passeggeri?_gl=1*6u6lrq*_up*MQ..*_ga*MTQ4MDUxMzY0Ni4xNjgxNzIzMjU1*_ga_171X9PNKC9*MTY4MTcyMzI1Mi4xLjEuMTY4MTcyMzI1Ni4wLjAuMA..",
    textKey: "footer-info-3",
  },
  {
    href: "https://www.gnv.it/it/assistenza/informative/annulla-iscrizione?_gl=1*1dgd55x*_up*MQ..*_ga*MTQ4MDUxMzY0Ni4xNjgxNzIzMjU1*_ga_171X9PNKC9*MTY4MTcyMzI1Mi4xLjEuMTY4MTcyMzI3NS4wLjAuMA..",
    textKey: "footer-info-4",
  },
  {
    href: "https://www.gnv.it/it/assistenza/informative/modello-di-organizzazione-gestione-e-controllo?_gl=1*589lfc*_up*MQ..*_ga*MTQ4MDUxMzY0Ni4xNjgxNzIzMjU1*_ga_171X9PNKC9*MTY4MTcyMzI1Mi4xLjEuMTY4MTcyMzI5NS4wLjAuMA..",
    textKey: "footer-info-5",
  },
  {
    href: "https://www.gnv.it/it/assistenza/informative/norme-per-l'uso?_gl=1*ickk54*_up*MQ..*_ga*MTQ4MDUxMzY0Ni4xNjgxNzIzMjU1*_ga_171X9PNKC9*MTY4MTcyMzI1Mi4xLjEuMTY4MTcyMzMzNi4wLjAuMA..",
    textKey: "footer-info-6",
  },
  {
    href: "https://info.gnv.it/images/pdf/it/privacy.pdf?_gl=1*a3qw4q*_up*MQ..*_ga*MjAwNzExMDA4My4xNjgxNzI0Nzcy*_ga_171X9PNKC9*MTY4MTcyNDc3MC4xLjAuMTY4MTcyNDc3MC4wLjAuMA..",
    textKey: "footer-info-7",
  },
  {
    href: "https://www.gnv.it/it/assistenza/contatti/segnalazioni?_gl=1*kd6y4b*_up*MQ..*_ga*MTA3MDc3MzI5Ni4xNjgxNzI0ODM1*_ga_171X9PNKC9*MTY4MTcyNDgzMi4xLjAuMTY4MTcyNDgzMi4wLjAuMA..",
    textKey: "footer-info-8",
  },
];

export const getSlides = (langCode) => [
  {
    url: `https://info.gnv.it/repository/remotebooking/lang/img/${langCode}1.jpg`,
  },
  {
    url: `https://info.gnv.it/repository/remotebooking/lang/img/${langCode}2.jpg`,
  },
  {
    url: `https://info.gnv.it/repository/remotebooking/lang/img/${langCode}3.jpg`,
  },
  {
    url: `https://info.gnv.it/repository/remotebooking/lang/img/${langCode}4.jpg`,
  },
  {
    url: `https://info.gnv.it/repository/remotebooking/lang/img/${langCode}5.jpg`,
  },
];
