import {
  Dialog,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { IUserData } from "@gnv/resevo-react-lib";
import FlagIcon from "@mui/icons-material/Flag";
import SettingsIcon from "@mui/icons-material/Settings";
import UserAvatar from "../sideMenuBar/userAvatar/UserAvatar";
import LanguageSection from "./languageSection/LanguageSection";
import "./userProfileModal.scss";
import FileExplorerSection from "./fileExplorerSection/FileExplorerSection";
import { Localizer } from "../../i18n/localizer";

const userProfilesMenus = [
  /* { name: "Privacy", icon: <SettingsIcon /> }, */
  {
    name: "Lingua",
    icon: <FlagIcon />,
    translation: Localizer.getInstance().translate("language"),
  },
  {
    name: "Documenti contabili",
    icon: <SettingsIcon />,
    translation: Localizer.getInstance().translate("accounting-documents"),
    data_testid: "accounting-documents-label",
  },
];

type IUserProfileModalProps = {
  handleOpen: () => void;
  handleClose: () => void;
  currentUser: IUserData;
  open: boolean;
  logout: () => void;
  onChangeLanguage: (lang: string) => void;
};
export default function UserProfileModal({
  open,
  handleClose,
  currentUser,
  logout,
  onChangeLanguage,
}: IUserProfileModalProps) {
  const [selectedMenu, setSelectedMenu] = React.useState("Generale");
  const handleChangeMenu = (menu: string) => {
    setSelectedMenu(menu);
  };
  type IMenuContentProps = {
    selectedMenu: string;
  };
  const MenuContent = ({ selectedMenu }: IMenuContentProps) => {
    switch (selectedMenu) {
      case "Generale":
        return <></>;
      case "Lingua":
        return <LanguageSection onChangeLanguage={onChangeLanguage} />;
      case "Documenti contabili":
        return <FileExplorerSection />;
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      className="user-modal-dialog"
      PaperProps={{ sx: { height: "70vh" } }}
    >
      <Grid container sx={{ height: "100%" }}>
        <Grid item xs={6} md={5} lg={4} xl={3.5} className="setting-modal-left">
          <Typography variant="h6">Impostazioni</Typography>
          <List className="list-menu-items-container">
            {userProfilesMenus.map((menu, index) => {
              return (
                <ListItem
                  className="user-profile-menu-item"
                  disablePadding
                  key={menu.name + index}
                  selected={menu.name === selectedMenu}
                  onClick={() => handleChangeMenu(menu.name)}
                  button
                  color="primary"
                  data-testid={menu.data_testid ? menu.data_testid : ""}
                >
                  <ListItemIcon
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText primary={menu.translation} />
                </ListItem>
              );
            })}
            <Divider sx={{ ml: 3, mr: 3 }} />
            <ListItem sx={{ p: 0 }}>
              <UserAvatar
                currentUser={currentUser}
                open={open}
                logout={logout}
                userProfileModal
              />
            </ListItem>
          </List>
        </Grid>
        <Grid sx={{ py: 3, px: 2 }} item xs={6} md={7} lg={8} xl={8.5}>
          <MenuContent selectedMenu={selectedMenu} />
        </Grid>
      </Grid>
    </Dialog>
  );
}
