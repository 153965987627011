import { Typography } from "@mui/material";
import React from "react";
import { RESEVO_LOCALES } from "../../../constants/appshellConstants";
import { Localizer } from "../../../i18n/localizer";
import LanguagePicker from "../../language-picker/LanguagePicker";

type ILanguageSectionProps = {
  onChangeLanguage: (lang: string) => void;
};

const LanguageSection = ({ onChangeLanguage }: ILanguageSectionProps) => {
  return (
    <>
      <Typography sx={{ pt: 1, pb: 2 }} variant="subtitle2">
        {Localizer.getInstance().translate("appshell-change-lan")}
      </Typography>

      <LanguagePicker
        languages={RESEVO_LOCALES}
        onChangeLanguage={onChangeLanguage}
      />
      <Typography
        sx={{ pt: 1, pl: 2, color: "rgba(0, 0, 0, 0.6)" }}
        variant="caption"
      >
        {Localizer.getInstance().translate("appshell-change-lan-description")}
      </Typography>
    </>
  );
};

export default LanguageSection;
