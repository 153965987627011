import React from 'react';
import {AppshellUtil} from '../utils/appshellUtil';

const useCurrentLanguage = () => {
  const [state, setState] = React.useState('');
  AppshellUtil.getInstance()
    .getAppshellApi()
    .on('select-language', (evt) => setState(evt.currentLanguage));
};

export default useCurrentLanguage;
