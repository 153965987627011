import React, {useContext} from "react";
import DirectionsBoatFilledRoundedIcon from "@mui/icons-material/DirectionsBoatFilledRounded";
import EditIcon from "@mui/icons-material/Edit";
import GroupsIcon from "@mui/icons-material/Groups";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HomeIcon from "@mui/icons-material/Home";
import {Operation, Visibility} from "@gnv/resevo-react-lib";
import {PermissionContext} from "@gnv/resevo-libportal-web";
import {
  AccountingSummaryIcon,
  AgenzieGestione,
  AnagraficaClienti,
  AnagraficaNave,
  BigliettiCheckin,
  BigliettiEmissione,
  GestioneBooking,
  GestioneCategoriaVendita,
  GestioneMezzi,
  GestioneOperativoViaggi,
  GestioneRisorseNavi,
  GestioneViaggi,
  MovementsIcon,
  OperationsSummaryIcon
} from "@gnv/resevo-icons";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import {Localizer} from "../../i18n/localizer";
import RoutesEnum from "../../constants/routesEnum";
import {IListMenuItem} from "../../models/IListMenuItem";
import {FeatureEnum} from "../../hooks/useEnabledFeatures";

export const getPiletClients = (
  isFeatureEnabled: (featureName: FeatureEnum) => boolean,
  hasOperation: (operations: string | string[], operationsUser) => boolean,
  operations
): IListMenuItem[] => {
  const getVisibility = useContext(PermissionContext);
  const hasCCtransactionsOperation =
    getVisibility([Operation.cctransac_r]) === Visibility.E;

  const items: IListMenuItem[] = [
    {
      name: Localizer.getInstance().translate("home"),
      icon: <HomeIcon style={{ width: "20px", height: "20px" }} />,
      route: RoutesEnum.HOME,
      key: "home",
      operations: [],
    },
    {
      name: Localizer.getInstance().translate(
        "appshell-menu-ships-resources-management"
      ),
      icon: (
        <GestioneRisorseNavi
          sx={{ width: "22px", height: "22px" }}
          viewBox="0 0 20 20"
        />
      ),
      route: "/navi/ships",
      key: "ships",
      operations: [Operation.shipresource_all, Operation.shipresource_x],
      isHidden: !hasOperation([Operation.shipresource_all, Operation.shipresource_x], operations)
    },
    {
      name: Localizer.getInstance().translate(
        "appshell-menu-sales-categories-management"
      ),
      icon: (
        <GestioneCategoriaVendita
          sx={{ width: "22px", height: "22px" }}
          viewBox="0 0 20 20"
        />
      ),
      route: "/pricing/gestione-categorie-vendita",
      key: "sales-categories",
      operations: [Operation.logcat_all, Operation.logcat_x],
      isHidden: !hasOperation([Operation.logcat_all, Operation.logcat_x], operations)
    },
    {
      name: Localizer.getInstance().translate("appshell-menu-ships-registry"),
      icon: (
        <AnagraficaNave
          sx={{ width: "22px", height: "22px" }}
          viewBox="0 0 20 20"
        />
      ),
      route: "/navi/anagrafica-navi",
      key: "ships-registry",
      operations: [Operation.ship_all, Operation.ship_x],
      isHidden: !hasOperation([Operation.ship_all, Operation.ship_x], operations)
    },

    {
      name: Localizer.getInstance().translate("appshell-menu-create-ticket"),
      icon: <BigliettiEmissione sx={{ width: "22px", height: "22px" }} />,
      route: RoutesEnum.BOOKING_EMIT,
      key: "ticket/create-ticket",
      operations: [Operation.bookapp_x],
      isHidden: !hasOperation([Operation.bookapp_x], operations)
    },
    {
      name: Localizer.getInstance().translate("appshell-menu-edit-ticket"),
      icon: <EditIcon />,
      route: RoutesEnum.BOOKING_EDIT,
      key: "/edit-ticket",
      operations: [Operation.booksc_x],
      isHidden: !hasOperation([Operation.booksc_x], operations)
    },
    //Removed for release RC EULISA 3.546 - https://gnv.atlassian.net/browse/RESEVO-12435
    // {
    //   name: Localizer.getInstance().translate("appshell-menu-bo-customer-care"),
    //   icon: <HeadsetMicIcon />,
    //   route: RoutesEnum.BO_CUSTOMER_CARE,
    //   key: "/bo/customer-care",
    //   operations: [Operation.bocc_x],
    //   isHidden: !hasOperation([Operation.bocc_x], operations)
    // },
    {
      name: Localizer.getInstance().translate("appshell-menu-sales-report"),
      icon: <QueryStatsIcon />,
      route: RoutesEnum.SALES_REPORT,
      key: "/booking/sales-report",
      operations: [Operation.borp_x],
      isHidden: !hasOperation([Operation.borp_x], operations)
    },
    {
      name: Localizer.getInstance().translate("app-shell-checkin"),
      icon: (
        <BigliettiCheckin
          sx={{ width: "22px", height: "22px" }}
          viewBox="0 0 20 20"
        />
      ),
      route: RoutesEnum.CHECKIN,
      key: "/check-in",
      operations: [Operation.checkinapp_x],
      isHidden: !hasOperation([Operation.checkinapp_x], operations)
    },
    {
      name: Localizer.getInstance().translate("appshell-menu-voys-calendar"),
      icon: <CalendarMonthIcon />,
      route: RoutesEnum.BOOKING_CALENDAR,
      key: "/booking-calendar",
      operations: [Operation.voysearchlight_l, Operation.voysearchlight_r],
      isHidden: !hasOperation([Operation.voysearchlight_l, Operation.voysearchlight_r], operations)
    },
    {
      name: Localizer.getInstance().translate(
        "appshell-menu-operational-variations"
      ),
      icon: <BigliettiEmissione sx={{ width: "22px", height: "22px" }} />,
      route: RoutesEnum.OPERATIONAL_VARIATIONS,
      key: "utilities/operational-variations",
      operations: [Operation.tktopchg_x],
      isHidden: !hasOperation([Operation.tktopchg_x], operations)
    },
    {
      name: Localizer.getInstance().translate(
        "appshell-menu-operations-summary"
      ),
      icon: <OperationsSummaryIcon sx={{ width: "22px", height: "22px" }} />,
      route: RoutesEnum.OPERATIONS_SUMMARY,
      key: "utilities/operations-summary",
      operations: [Operation.tktevope_x],
      isHidden: !hasOperation([Operation.tktevope_x], operations)
    },
    {
      name: Localizer.getInstance().translate(
        "appshell-menu-accounting-summary"
      ),
      icon: <AccountingSummaryIcon sx={{ width: "22px", height: "22px" }} />,
      route: RoutesEnum.ACCOUNTING_SUMMARY,
      key: "utilities/accounting-summary",
      operations: [Operation.ageaccount_x],
      isHidden: !hasOperation([Operation.ageaccount_x], operations)
    },
    {
      name: Localizer.getInstance().translate(
        hasCCtransactionsOperation
          ? "appshell-menu-cc-transactions-list"
          : "appshell-menu-movements-list"
      ),
      icon: <MovementsIcon sx={{ width: "22px", height: "22px" }} />,
      route: RoutesEnum.MOVEMENTS_LIST,
      key: "utilities/movements-list",
      operations: [Operation.cctransac_r, Operation.pptransac_r],
      isHidden: !hasOperation([Operation.cctransac_r, Operation.pptransac_r], operations)
    },

    {
      name: Localizer.getInstance().translate(
        "appshell-menu-groups-management"
      ),
      icon: <GroupsIcon />,
      route: "/prenotazione-gruppi/emit",
      key: "groups-management",
      operations: ["hide me for the moment" as Operation],
      isHidden: !hasOperation([Operation.cctransac_r, Operation.pptransac_r], operations)
    },
    {
      name: Localizer.getInstance().translate("appshell-menu-manage-voys"),
      icon: (
        <GestioneViaggi
          sx={{ width: "22px", height: "22px" }}
          viewBox="0 0 20 20"
        />
      ),
      route: "/viaggi/voys",
      key: "voys",
      operations: [Operation.voy_all, Operation.voy_x],
      isHidden: !hasOperation([Operation.voy_all, Operation.voy_x], operations)
    },
    {
      name: Localizer.getInstance().translate(
        "appshell-menu-manage-voys-generation"
      ),
      icon: (
        <GestioneOperativoViaggi
          sx={{ width: "22px", height: "22px" }}
          viewBox="0 0 20 20"
        />
      ),
      route: "/viaggi/generazione-operativo-viaggi",
      key: "voygen",
      operations: [Operation.voygrid_x],
      isHidden: !hasOperation([Operation.voygrid_x], operations)
    },
    {
      name: Localizer.getInstance().translate(
        "appshell-menu-agencies-management"
      ),
      icon: <AgenzieGestione sx={{ width: "22px", height: "22px" }} />,
      route: "/agenzia/gestione-agenzie",
      key: "agencies-management",
      operations: [Operation.boagency_x, Operation.boagency_all],
      isHidden: !hasOperation([Operation.boagency_x, Operation.boagency_all], operations)
    },
    //Removed for release RC EULISA 3.546 - https://gnv.atlassian.net/browse/RESEVO-12435
    // {
    //   name: Localizer.getInstance().translate(
    //     "appshell-menu-blacklist"
    //   ),
    //   icon: <GestioneBlacklistPax sx={{ width: "22px", height: "22px" }} />,
    //   route: "/back-office/passeggeri/gestione-blacklist",
    //   key: "gestione-blacklist",
    //   children: [],
    //   operations: [Operation.blkpax_x, Operation.blkpax_all],
    //   isHidden: !hasOperation([Operation.blkpax_x, Operation.blkpax_all], operations)
    // },
    //Removed for release RC EULISA 3.546 - https://gnv.atlassian.net/browse/RESEVO-12435
    // {
    //   name: Localizer.getInstance().translate(
    //     "appshell-menu-reversals-management"
    //   ),
    //   icon: <GestioneReversali sx={{ width: "22px", height: "22px" }} />,
    //   route: "/reversale/gestione-reversali",
    //   key: "reversals-management",
    //   operations: [Operation.boagency_x, Operation.boagency_all],
    //   isHidden: !isFeatureEnabled(FeatureEnum.REVERSALS) && !hasOperation([Operation.boagency_x, Operation.boagency_all], operations),
    // },
      //Removed for release RC EULISA 3.546 - https://gnv.atlassian.net/browse/RESEVO-12435
    // {
    //   name: Localizer.getInstance().translate(
    //     "appshell-menu-management-sales-grid"
    //   ),
    //   icon: <GestioneGrigliaSconti sx={{ width: "22px", height: "22px" }} />,
    //   route: "/pricing/gestione-griglia-sconti",
    //   key: "management-sales-grid",
    //   children: [],
    //   operations: [Operation.dscprc_x, Operation.dscprc_all],
    //   isHidden: !isFeatureEnabled(FeatureEnum.DISCOUNT_GRID) && !hasOperation([Operation.dscprc_x, Operation.dscprc_all], operations),
    // },
    {
      name: Localizer.getInstance().translate(
        "appshell-menu-customer-registry"
      ),
      icon: (
        <AnagraficaClienti
          sx={{ width: "22px", height: "22px" }}
          viewBox="0 0 20 20"
        />
      ),
      route: "/clienti/anagrafica-clienti",
      key: "customer-registry",
      operations: [Operation.fcpmcu_all, Operation.fcpmcu_x],
      isHidden: !hasOperation([Operation.fcpmcu_all, Operation.fcpmcu_x], operations)
    },
    {
      name: Localizer.getInstance().translate(
        "appshell-menu-freight-management"
      ),
      icon: (
        <GestioneMezzi
          sx={{ width: "22px", height: "22px" }}
          viewBox="0 0 20 20"
        />
      ),
      route: "/mezzi/gestione-mezzi",
      key: "freight-management",
      operations: [Operation.fpriloca_all, Operation.fpriloca_x],
      isHidden: !hasOperation([Operation.fpriloca_all, Operation.fpriloca_x], operations)
    },
    //Removed for release RC EULISA 3.546 - https://gnv.atlassian.net/browse/RESEVO-12122
 /*    {
      name: Localizer.getInstance().translate("appshell-menu-freight-booking"),
      icon: (
        <GestioneBooking
          sx={{ width: "22px", height: "22px" }}
          viewBox="0 0 20 20"
        />
      ),
      route: "/freight-booking/booking",
      key: "freight-booking",
      operations: [Operation.fbokapp_x],
      isHidden: !hasOperation([Operation.fbokapp_x], operations)
    }, */
    {
      name: Localizer.getInstance().translate(
        "appshell-menu-agencies-sub-codes-management"
      ),
      icon: <AgenzieGestione sx={{ width: "22px", height: "22px" }} />,
      route: "/sotto-codici/sotto-codici-agenzie",
      key: "sotto-codici-agenzie",
      operations: [Operation.bosubagency_x, Operation.bosubagency_all],
      isHidden: !hasOperation([Operation.bosubagency_x, Operation.bosubagency_all], operations)
    },
    {
      name: Localizer.getInstance().translate(
        "appshell-menu-agencies-user-management"
      ),
      icon: <AgenzieGestione sx={{ width: "22px", height: "22px" }} />,
      route: "/agenzia/gestione-utenze",
      key: "gestione-utenze",
      children: [],
      operations: [Operation.usrextusrext_x, Operation.usrextusrext_all],
      isHidden: !hasOperation([Operation.usrextusrext_x, Operation.usrextusrext_all], operations)
    },

    /* {
                             name: 'gnv Monitor',
                             icon: <TravelExplore />,
                             route: '/gnvmonitor',
                             key: 'gnvmonitor',
                           },
                           {
                             name: Localizer.getInstance().translate('appshell-menu-edit-ticket'),
                             icon: <ConfirmationNumber />,
                             route: '/edit-tickets',
                             key: 'ticket/edit-ticket',
                           },
                           {
                             name: Localizer.getInstance().translate('appshell-menu-ports'),
                             icon: <Houseboat />,
                             route: '/ports',
                             key: 'ports',
                             children: [],
                           },
                           {
                             name: Localizer.getInstance().translate('appshell-menu-ships-resources-management'),
                             icon: <DirectionsBoatFilledRoundedIcon />,
                             route: '/ships',
                             key: 'ships',
                             children: [],
                           }, */

    /*  {
                            name: Localizer.getInstance().translate('appshell-menu-travel-count'),
                            icon: <TravelExplore />,
                            route: '/viaggi-conteggio',
                            key: 'viaggi-conteggio',
                          },

                            {
                            name: Localizer.getInstance().translate('appshell-menu-search-ticket'),
                            icon: <Edit />,
                            route: '/search-ticket',
                            key: 'ticket/search-ticket',
                          },
                          {
                            name: Localizer.getInstance().translate('appshell-menu-groups-report'),
                            icon: <Edit />,
                            route: '/groups-report',
                            key: 'groups-report',
                          },
                          {
                            name: Localizer.getInstance().translate('appshell-menu-booked-report'),
                            icon: <Edit />,
                            route: '/booked-report',
                            key: 'booked-report',
                          },
                          {
                            name: Localizer.getInstance().translate('appshell-menu-sells-report'),
                            icon: <Edit />,
                            route: '/sales-report',
                            key: 'sales-report',
                          },
                          {
                            name: Localizer.getInstance().translate('appshell-menu-groups'),
                            icon: <Edit />,
                            route: '/groups',
                            key: 'groups',
                          },

                          {
                            name: Localizer.getInstance().translate('appshell-menu-agency'),
                            icon: <Edit />,
                            route: '/agency',
                            key: 'agency',
                          },
                          {
                            name: Localizer.getInstance().translate('appshell-menu-coupon-covid'),
                            icon: <Edit />,
                            route: '/coupon-covid',
                            key: 'coupon-covid',
                          }, */
  ];

  return items.filter(({ isHidden }) => !isHidden);
};
export const getPiletsByCategories = (
  isFeatureEnabled: (featureName: FeatureEnum) => boolean,
  hasOperation: (operations: string | string[], operationsUser) => boolean,
  operations
): IListMenuItem[] => {
  const getVisibility = useContext(PermissionContext);
  const hasCCtransactionsOperation =
    getVisibility([Operation.cctransac_r]) === Visibility.E;
  const items: IListMenuItem[] = [
    {
      name: "setup",
      key: "setup",
      children: [
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-ships-resources-management"
          ),
          icon: <DirectionsBoatFilledRoundedIcon />,
          route: "/navi/ships",
          key: "ships",
          operations: [Operation.shipresource_all, Operation.shipresource_x],
          isHidden: !hasOperation([Operation.shipresource_all, Operation.shipresource_x], operations)
        },
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-sales-categories-management"
          ),
          icon: (
            <GestioneCategoriaVendita
              sx={{ width: "22px", height: "22px" }}
              viewBox="0 0 20 20"
            />
          ),
          route: "/pricing/gestione-categorie-vendita",
          key: "sales-categories",
          operations: [Operation.logcat_all, Operation.logcat_x],
          isHidden: !hasOperation([Operation.logcat_all, Operation.logcat_x], operations)
        },
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-ships-registry"
          ),
          icon: (
            <AnagraficaNave
              sx={{ width: "22px", height: "22px" }}
              viewBox="0 0 20 20"
            />
          ),
          route: "/navi/anagrafica-navi",
          key: "ships-registry",
          operations: [Operation.ship_all, Operation.ship_x],
          isHidden: !hasOperation([Operation.ship_all, Operation.ship_x], operations)
        },
      ],
      operations: [
        Operation.logcat_all,
        Operation.logcat_x,
        Operation.shipresource_all,
        Operation.shipresource_x,
        Operation.ship_all,
        Operation.ship_x,
      ],
      isHidden: !hasOperation([
        Operation.logcat_all,
        Operation.logcat_x,
        Operation.shipresource_all,
        Operation.shipresource_x,
        Operation.ship_all,
        Operation.ship_x,
      ], operations)
    },
    {
      name: "booking pax",
      key: "bookingPax",
      children: [
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-create-ticket"
          ),
          icon: <BigliettiEmissione sx={{ width: "22px", height: "22px" }} />,
          route: RoutesEnum.BOOKING_EMIT,
          key: "ticket/create-ticket",
          operations: [Operation.bookapp_x],
          isHidden: !hasOperation([Operation.bookapp_x], operations)
        },
        {
          name: Localizer.getInstance().translate("appshell-menu-edit-ticket"),
          icon: <EditIcon />,
          route: RoutesEnum.BOOKING_EDIT,
          key: "edit_booking",
          operations: [Operation.booksc_x],
          isHidden: !hasOperation([Operation.booksc_x], operations)
        },
        //Removed for release RC EULISA 3.546 - https://gnv.atlassian.net/browse/RESEVO-12435
        // {
        //   name: Localizer.getInstance().translate(
        //     "appshell-menu-bo-customer-care"
        //   ),
        //   icon: <HeadsetMicIcon />,
        //   route: RoutesEnum.BO_CUSTOMER_CARE,
        //   key: "bo_customer_care",
        //   operations: [Operation.bookapp_x], // TODO update in RESEVO-8816 work
        //   isHidden: !hasOperation([Operation.bookapp_x], operations)
        // },
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-voys-calendar"
          ),
          icon: <CalendarMonthIcon />,
          route: RoutesEnum.BOOKING_CALENDAR,
          key: "booking_calendar",
          operations: [Operation.voysearchlight_l, Operation.voysearchlight_r],
          isHidden: !hasOperation([Operation.voysearchlight_l, Operation.voysearchlight_r], operations)
        },
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-groups-management"
          ),
          icon: <GroupsIcon />,
          route: "/prenotazione-gruppi/emit",
          key: "groups-management",
          operations: ["hide me for the moment" as Operation],
          isHidden: !hasOperation(["hide me for the moment" as Operation], operations)
        },
        {
          name: Localizer.getInstance().translate("appshell-menu-sales-report"),
          icon: <QueryStatsIcon />,
          route: RoutesEnum.SALES_REPORT,
          key: "/booking/sales-report",
          operations: [Operation.borp_x],
          isHidden: !hasOperation([Operation.borp_x], operations)
        },
      ],
      operations: [
        Operation.bookapp_x,
        Operation.booksc_x,
        Operation.voysearchlight_all,
        Operation.voysearchlight_l,
        Operation.voysearchfull_all,
        Operation.voysearchfull_l,
      ],
      isHidden: !hasOperation([
        Operation.bookapp_x,
        Operation.booksc_x,
        Operation.voysearchlight_all,
        Operation.voysearchlight_l,
        Operation.voysearchfull_all,
        Operation.voysearchfull_l,
      ], operations)
    },

    {
      name: Localizer.getInstance().translate("checkin-boarding-pax"),
      key: "checkin-boarding-pax",
      children: [
        {
          name: Localizer.getInstance().translate("app-shell-checkin"),
          icon: (
            <BigliettiCheckin
              sx={{ width: "22px", height: "22px" }}
              viewBox="0 0 20 20"
            />
          ),
          route: RoutesEnum.CHECKIN,
          key: "/check-in",
          operations: [Operation.checkinapp_x],
          isHidden: !hasOperation([Operation.checkinapp_x], operations)
        },
      ],
      operations: [Operation.checkinapp_x],
      isHidden: !hasOperation([Operation.checkinapp_x], operations)
    },

    {
      name: Localizer.getInstance().translate("appshell-menu-utilities"),
      key: "utilities",
      children: [
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-operational-variations"
          ),
          icon: <BigliettiEmissione sx={{ width: "22px", height: "22px" }} />,
          route: RoutesEnum.OPERATIONAL_VARIATIONS,
          key: "utilities_operational-variations",
          operations: [Operation.tktopchg_x],
          isHidden: !hasOperation([Operation.tktopchg_x], operations)
        },
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-operations-summary"
          ),
          icon: (
            <OperationsSummaryIcon sx={{ width: "22px", height: "22px" }} />
          ),
          route: RoutesEnum.OPERATIONS_SUMMARY,
          key: "utilities_operations-summary",
          operations: [Operation.tktevope_x],
          isHidden: !hasOperation([Operation.tktevope_x], operations)
        },
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-accounting-summary"
          ),
          icon: (
            <AccountingSummaryIcon sx={{ width: "22px", height: "22px" }} />
          ),
          route: RoutesEnum.ACCOUNTING_SUMMARY,
          key: "utilities_accounting-summary",
          operations: [Operation.ageaccount_x],
          isHidden: !hasOperation([Operation.ageaccount_x], operations)
        },
        {
          name: Localizer.getInstance().translate(
            hasCCtransactionsOperation
              ? "appshell-menu-cc-transactions-list"
              : "appshell-menu-movements-list"
          ),
          icon: <MovementsIcon sx={{ width: "22px", height: "22px" }} />,
          route: RoutesEnum.MOVEMENTS_LIST,
          key: "utilities_movements-list",
          operations: [Operation.cctransac_r, Operation.pptransac_r],
          isHidden: !hasOperation([Operation.cctransac_r, Operation.pptransac_r], operations)
        },
      ],
      operations: [
        Operation.tktopchg_x,
        Operation.ageaccount_x,
        Operation.tktevope_x,
        Operation.cctransac_r,
        Operation.pptransac_r,
      ],
      isHidden: !hasOperation([
        Operation.tktopchg_x,
        Operation.ageaccount_x,
        Operation.tktevope_x,
        Operation.cctransac_r,
        Operation.pptransac_r,
      ], operations)
    },
    {
      name: "deploy",
      key: "deploy",
      children: [
        {
          name: Localizer.getInstance().translate("appshell-menu-manage-voys"),
          icon: (
            <GestioneViaggi
              sx={{ width: "22px", height: "22px" }}
              viewBox="0 0 20 20"
            />
          ),
          route: "/viaggi/voys",
          key: "voys",
          children: [],
          operations: [Operation.voy_all, Operation.voy_x],
          isHidden: !hasOperation([Operation.voy_all, Operation.voy_x], operations)
        },
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-manage-voys-generation"
          ),
          icon: (
            <GestioneOperativoViaggi
              sx={{ width: "22px", height: "22px" }}
              viewBox="0 0 20 20"
            />
          ),
          route: "/viaggi/generazione-operativo-viaggi",
          key: "voygen",
          children: [],
          operations: [Operation.voygrid_x],
          isHidden: !hasOperation([Operation.voygrid_x], operations)
        },
        /* {
                                                      name: Localizer.getInstance().translate("appshell-menu-travel-count"),
                                                      icon: <TravelExplore />,
                                                      route: "/viaggi-conteggio",
                                                      key: "viaggi-conteggio",
                                                    }, */
      ],
      operations: [Operation.voy_all, Operation.voy_x, Operation.voygrid_x],
      isHidden: !hasOperation([Operation.voy_all, Operation.voy_x, Operation.voygrid_x], operations)
    },
    {
      name: "backoffice pax",
      key: "backofficePax",
      children: [
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-agencies-management"
          ),
          icon: <AgenzieGestione sx={{ width: "22px", height: "22px" }} />,
          route: "/agenzia/gestione-agenzie",
          key: "gestione-agenzie",
          children: [],
          operations: [Operation.boagency_x, Operation.boagency_all],
          isHidden: !hasOperation([Operation.boagency_x, Operation.boagency_all], operations)
        },
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-agencies-sub-codes-management"
          ),
          icon: <AgenzieGestione sx={{ width: "22px", height: "22px" }} />,
          route: "/sotto-codici/sotto-codici-agenzie",
          key: "sotto-codici-agenzie",
          children: [],
          operations: [Operation.bosubagency_x, Operation.bosubagency_all],
          isHidden: !hasOperation([Operation.bosubagency_x, Operation.bosubagency_all], operations)
        },
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-agencies-user-management"
          ),
          icon: <AgenzieGestione sx={{ width: "22px", height: "22px" }} />,
          route: "/agenzia/gestione-utenze",
          key: "gestione-utenze",
          children: [],
          operations: [Operation.usrextusrext_x, Operation.usrextusrext_all],
          isHidden: !hasOperation([Operation.usrextusrext_x, Operation.usrextusrext_all], operations)
        },
        //Removed for release RC EULISA 3.546 - https://gnv.atlassian.net/browse/RESEVO-12435
        // {
        //   name: Localizer.getInstance().translate(
        //     "appshell-menu-blacklist"
        //   ),
        //   icon: <GestioneBlacklistPax sx={{ width: "22px", height: "22px" }} />,
        //   route: "/back-office/passeggeri/gestione-blacklist",
        //   key: "gestione-blacklist",
        //   children: [],
        //   operations: [Operation.boagency_x, Operation.boagency_all],
        //   isHidden: !hasOperation([Operation.boagency_x, Operation.boagency_all], operations),
        // },
        //Removed for release RC EULISA 3.546 - https://gnv.atlassian.net/browse/RESEVO-12435
        // {
        //   name: Localizer.getInstance().translate(
        //     "appshell-menu-reversals-management"
        //   ),
        //   icon: <GestioneReversali sx={{ width: "22px", height: "22px" }} />,
        //   route: "/reversale/gestione-reversali",
        //   key: "reversals-management",
        //   children: [],
        //   operations: [Operation.boagency_x, Operation.boagency_all],
        //   isHidden: !isFeatureEnabled(FeatureEnum.REVERSALS) && !hasOperation([Operation.boagency_x, Operation.boagency_all], operations),
        // },
        //Removed for release RC EULISA 3.546 - https://gnv.atlassian.net/browse/RESEVO-12435
        // {
        //   name: Localizer.getInstance().translate(
        //     "appshell-menu-management-sales-grid"
        //   ),
        //   icon: (
        //     <GestioneGrigliaSconti sx={{ width: "22px", height: "22px" }} />
        //   ),
        //   route: "/pricing/gestione-griglia-sconti",
        //   key: "management-sales-grid",
        //   children: [],
        //   operations: [Operation.dscprc_x, Operation.dscprc_all],
        //   isHidden: !isFeatureEnabled(FeatureEnum.DISCOUNT_GRID) && !hasOperation([Operation.dscprc_x, Operation.dscprc_all], operations),
        // },
      ],
      operations: [
        Operation.boagency_x,
        Operation.bosubagency_x,
        Operation.usrextusrext_x,
        Operation.usrextusrext_all,
        Operation.dscprc_x,
        Operation.dscprc_all,
      ],
      isHidden: !hasOperation([
        Operation.boagency_x,
        Operation.bosubagency_x,
        Operation.usrextusrext_x,
        Operation.usrextusrext_all,
        Operation.dscprc_x,
        Operation.dscprc_all,
      ], operations)
    },
    /* {
                            name: "check-in & boarding pax",
                            key: "check_inBoardingPax",
                            children: [],
                          }, */
    /*   {
                            name: "booking freight",
                            key: "bookingFreight",
                            children: [
                              {
                                name: "Listini",
                                icon: <HorizontalSplitIcon />,
                                route: "/Listini",
                                key: "Listini",
                                children: [],
                              },
                              {
                                name: "Polizze",
                                icon: <HorizontalSplitIcon />,
                                route: "/Polizze",
                                key: "Polizze",
                                children: [],
                              },
                              {
                                name: "Polizze di servizio",
                                icon: <HorizontalSplitIcon />,
                                route: "/Polizze di servizio",
                                key: "Polizze di servizio",
                                children: [],
                              },
                              {
                                name: "Promo",
                                icon: <HorizontalSplitIcon />,
                                route: "/Promo",
                                key: "Promo",
                                children: [],
                              },
                              {
                                name: "Tariffe",
                                icon: <HorizontalSplitIcon />,
                                route: "/Tariffe",
                                key: "Tariffe",
                                children: [],
                              },
                            ],
                          }, */
    // //Removed for release RC EULISA 3.546 - https://gnv.atlassian.net/browse/RESEVO-12122
    /* {
      name: "booking freight",
      key: "bookingFreight",
      children: [
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-freight-booking"
          ),
          icon: (
            <GestioneBooking
              sx={{ width: "22px", height: "22px" }}
              viewBox="0 0 20 20"
            />
          ),
          route: "/freight-booking/booking",
          key: "freight-booking",
          children: [],
          operations: [Operation.fbokapp_x],
          isHidden: !hasOperation([Operation.fbokapp_x], operations)
        },
      ],
      operations: [Operation.fbokapp_x],
      isHidden: !hasOperation([Operation.fbokapp_x], operations)
    }, */
    {
      name: "backoffice freight",
      key: "backofficeFreight",
      children: [
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-customer-registry"
          ),
          icon: (
            <AnagraficaClienti
              sx={{ width: "22px", height: "22px" }}
              viewBox="0 0 20 20"
            />
          ),
          route: "/clienti/anagrafica-clienti",
          key: "customer-registry",
          operations: [Operation.fcpmcu_all, Operation.fcpmcu_x],
          isHidden: !hasOperation([Operation.fcpmcu_all, Operation.fcpmcu_x], operations)
        },
        {
          name: Localizer.getInstance().translate(
            "appshell-menu-freight-management"
          ),
          icon: (
            <GestioneMezzi
              sx={{ width: "22px", height: "22px" }}
              viewBox="0 0 20 20"
            />
          ),
          route: "/mezzi/gestione-mezzi",
          key: "freight-management",
          operations: [Operation.fpriloca_all, Operation.fpriloca_x],
          isHidden: !hasOperation([Operation.fpriloca_all, Operation.fpriloca_x], operations)
        },
      ],
      operations: [
        Operation.fcpmcu_all,
        Operation.fcpmcu_x,
        Operation.fpriloca_all,
      ],
      isHidden: !hasOperation([
        Operation.fcpmcu_all,
        Operation.fcpmcu_x,
        Operation.fpriloca_all,
      ], operations)
    },
  ];

  return items.reduce((_acc, _item) => {
    if (_item.isHidden) return _acc;

    const itemWithVisibleChildren = {
      ..._item,
      children: _item.children?.filter(({ isHidden }) => !isHidden),
    };
    return [..._acc, itemWithVisibleChildren];
  }, []);
};
