import React, { useEffect, useState } from "react";
import { Grid, ListItemText, Typography } from "@mui/material";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ImageIcon from "@mui/icons-material/Image";
import fileService from "../../../services/FileService";
import { Localizer } from "../../../i18n/localizer";
import { Document } from "./types";

const FileExplorerSection = () => {
  const [treeData, setTreeData] = useState<Document[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<Document[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);

  const [currentId, setCurrentId] = useState<string>("");

  useEffect(() => {
    const fetchRootNodes = async () => {
      const response = await fileService.getTreeData();
      // eslint-disable-next-line no-underscore-dangle
      setTreeData(response.data._embedded.documents);
    };

    fetchRootNodes();
  }, []);

  const handleToggle = (event, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const findNodeIndexInTreeData = (nodeId: string) => {
    return treeData.findIndex((node) => node.nodeId === nodeId);
  };

  const loadChildrenForNode = async (nodeId: string) => {
    const response = await fileService.getTreeData(nodeId);
    // eslint-disable-next-line no-underscore-dangle
    return response.data._embedded.documents;
  };
  const expandFolderNode = async (nodeIndex: number, nodeId: string) => {
    const isExpanded = expanded.includes(nodeId);
    let { children } = treeData[nodeIndex];

    if (!children) {
      children = await loadChildrenForNode(nodeId);
      const updatedTreeData = [...treeData];
      updatedTreeData[nodeIndex].children = children;
      setTreeData(updatedTreeData);
    }

    const files = children.filter((child) => child.type === "file");
    if (files.length > 0) {
      setSelectedFiles(files);
    }

    if (!isExpanded) {
      setExpanded((oldExpanded) => [...oldExpanded, nodeId]);
    }
  };
  const loadAndDisplayFilesForNode = async (nodeId: string) => {
    if (nodeId !== currentId) {
      setExpanded((prev) => prev.filter((id) => id !== currentId));
    }
    setCurrentId(nodeId);
    const files = await loadChildrenForNode(nodeId);
    setSelectedFiles(files.filter((file) => file.type === "file"));
  };

  const handleFolderSelection = async (nodeId: string) => {
    const nodeIndex = findNodeIndexInTreeData(nodeId);

    if (nodeIndex !== -1) {
      await expandFolderNode(nodeIndex, nodeId);
    } else {
      await loadAndDisplayFilesForNode(nodeId);
    }
  };
  const handleNodeSelect = async (nodeId: string, nodeType: string) => {
    setSelectedFiles([]);
    if (nodeType === "folder") {
      await handleFolderSelection(nodeId);
    }
  };

  const renderTree = (nodes: Document[]) =>
    nodes.map((node) => (
      <TreeItem
        key={node.nodeId}
        nodeId={node.nodeId}
        label={node.name}
        onClick={() => handleNodeSelect(node.nodeId, node.type)}
        collapseIcon={node.nodeId !== currentId ? <RemoveIcon /> : null}
        data-testid="accounting-documents-element-tree"
      >
        {(node.children &&
          node.children.length > 0 &&
          renderTree(node.children)) || [<div key="stub" />]}
      </TreeItem>
    ));

  return (
    <Grid container spacing={2} data-testid="accounting-documents-columns">
      <Grid item xs={12} sm={4}>
        <Box sx={{ borderRight: "1px solid #e0e0e0", pr: 2 }}>
          <Typography variant="h6">
            {Localizer.getInstance().translate("appshell-year")}
          </Typography>
          <TreeView
            aria-label="file system navigator"
            defaultExpandIcon={<AddIcon />}
            expanded={expanded}
            onNodeToggle={handleToggle}
          >
            {renderTree(treeData)}
          </TreeView>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box sx={{ pl: "2rem" }}>
          <Typography variant="h6">
            {Localizer.getInstance().translate("appshell-file")}
          </Typography>
          <List dense>
            {selectedFiles.map((file) => (
              <ListItem
                key={file.nodeId}
                onClick={() => fileService.downloadFile(file.nodeId, file.path)}
              >
                <ImageIcon />
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    lineHeight: "1.2",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FileExplorerSection;
