import {
  createHttpBaseService,
  REACT_APP_FEED_SERVICE_URL,
  REACT_APP_API_URL,
} from "@gnv/resevo-libportal-web";
import { LOGOUT_URL } from "../constants/appshellConstants";
// change to your feed URL here (either using feed.piral.cloud or your own service)
const base = REACT_APP_API_URL;
const feedUrl = base + REACT_APP_FEED_SERVICE_URL;

const piletService = createHttpBaseService(feedUrl, null, LOGOUT_URL);
export default piletService;
