import { Divider, Grid } from "@mui/material";
import React from "react";
import "./errorPage.scss";
import { BlueGnvLogo, Error404 } from "@gnv/resevo-icons";
import { Localizer } from "../../i18n/localizer";

function ErrorPage404() {
  return (
    <Grid container className={"error-page-container"}>
      <Grid item>
        <Error404 viewBox="0 0 470 478" sx={{ width: "470px", height: "478px" }} />
      </Grid>

      <Grid item className="error-text-content">
        <p className="text-404-head">404</p>
        <p className="error-404-message">
          {Localizer.getInstance().translate("404-error-message")}
        </p>
        <Divider className="error-page-divider" />
        <BlueGnvLogo viewBox="0 0 113 31" sx={{ width: "113px", height: "31px" }} />
      </Grid>
    </Grid>
  );
}

export default ErrorPage404;
