import {PiletApi, PiralInstance} from "piral";
import {translations} from "../i18n/translations";

export class AppshellUtil {
    private static _instance: AppshellUtil;
    private readonly _appshellInstance: PiralInstance;
    private readonly _appshellApi: PiletApi;

    private constructor(appshellInstance: PiralInstance, appshellApi: PiletApi) {
        this._appshellInstance = appshellInstance;
        this._appshellApi = appshellApi;
        this._appshellApi.setTranslations(translations);
    }

    public static init(appshellInstance: PiralInstance, appshellApi: PiletApi): AppshellUtil{
        return this._instance || (this._instance = new this(appshellInstance, appshellApi));
    }

    public static getInstance(): AppshellUtil{
        if(!this._instance){
            throw new Error("AppshellUtil is not initialized. Call AppshellUtil.init(appshellInstance, appshellApi) on application start");
        }
        return this._instance;
    }

    public getAppshellInstance(): PiralInstance{
        return this._appshellInstance;
    }

    public getAppshellApi(): PiletApi{
        return this._appshellApi;
    }
}