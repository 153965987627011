import { GlobalStateContext } from "piral-core";
//to use in boportal when needed
export const toggleAppLoading = (ctx: GlobalStateContext) => {
  ctx.dispatch((state) => ({
    ...state,
    app: {
      ...state.app,
      loading: !state.app.loading,
    },
  }));
};
export const disableAppMenu = (ctx: GlobalStateContext) => {
  ctx.dispatch((state) => ({
    ...state,
    menuDisabled: true,
  }));
};
export const enableAppMenu = (ctx: GlobalStateContext) => {
  ctx.dispatch((state) => ({
    ...state,
    menuDisabled: false,
  }));
};