import { PiralPlugin } from "piral";

export interface MyPiletApi {
  setLoadingPilets(val: boolean): void;

  setMenuDisabled(val: boolean): void;
}

export const createMyApi = (): PiralPlugin<MyPiletApi> => {
  return (context) => ({

    setLoadingPilets(val: boolean) {
      return context.dispatch((state) => ({
        ...state,
        loadingPilets: val
      }));
    },

    setMenuDisabled(val: boolean) {
      return context.dispatch((state) => ({
        ...state,
        menuDisabled: val
      }));
    }
  });
};
