import {Paper} from "@mui/material";
import React from "react";
import Login from "../../components/login/Login";
import ResevoColorBlueLogo from "../../static/images/resevoColorBlue.svg";
import "./internalHomePage.scss";
import {Localizer} from "../../i18n/localizer";

const InternalHomePage: React.FC = () => {
  return (
    <div className="internal-background-home-page" id="background-home-page">
      <Paper className="internal-paper-login">
        <Login
          logo={ResevoColorBlueLogo}
          height="auto"
          width="276.61px"
          loginTitle={Localizer.getInstance().translate(
             "login-title-internal"
          )}
        />
      </Paper>
    </div>
  );
};

export default InternalHomePage;
