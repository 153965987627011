import { Logger, MainHeaderLayout, resevoTheme } from "@gnv/resevo-react-lib";
import {
  Box,
  createTheme,
  Grid,
  Paper,
  ThemeOptions,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { locales } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import LanguagePicker from "../../components/language-picker/LanguagePicker";
import Login from "../../components/login/Login";
import { RESEVO_LOCALES } from "../../constants/appshellConstants";
import { Localizer } from "../../i18n/localizer";
import { SupportedLocales } from "../../layouts/default-layout/DefaultLayout";
import LogoGNVsvg from "../../static/images/logoGNV.svg";
import LogoGNVbooking from "../../static/images/LogoGNVBooking.png";
import CustomCarousel from "./carousel/CustomCarousel";
import "./externalHomePage.scss";
import FooterSection from "./footer/ExternalHomePageFooter";

const ExternalHomePage: React.FC = () => {
  const [locale, setLocale] = useState("it");
  const [langCode, setLangCode] = useState("it");
  const [languageChangedByUser, setLanguageChangedByUser] = useState(false);
  const selectedLanguage = locale.substring(0, 2);

  const getCurrentLangCode = (): string => {
    try {
      return Localizer.getInstance().getCurrentLanguage().substring(0, 2);
    } catch (err) {
      Logger.error("Error while getting current language", err);
      return "en";
    }
  };

  useEffect(() => {
    const currentLangCode = getCurrentLangCode();
    setLangCode(currentLangCode);
    setLocale(Localizer.getInstance().getCurrentLanguage() as SupportedLocales);
  }, []);

  const handleLocaleChange = (lang: SupportedLocales) => {
    setLocale(lang);
    setLanguageChangedByUser(true);
  };

  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(resevoTheme as ThemeOptions, locales[locale]),
    [locale, theme]
  );

  return (
    <ThemeProvider theme={themeWithLocale}>
      <div className="external-background-home-page" id="background-home-page">
        <MainHeaderLayout>
          <Grid container>
            <Grid item>
              <div className="gnv-logo-header">
                <img src={LogoGNVsvg} alt="logoGNV" />
              </div>
            </Grid>
            <Grid item xs={2.8}>
              <LanguagePicker
                languages={RESEVO_LOCALES}
                onChangeLanguage={handleLocaleChange}
              />
            </Grid>
          </Grid>
        </MainHeaderLayout>
        <div className="external-login-container">
          <Paper className="external-paper-login">
            <Login
              logo={LogoGNVbooking}
              height="35x"
              width="300px"
              loginTitle={Localizer.getInstance().translate(
                "login-title-external"
              )}
            />
            <Box width={"100%"}>
              <iframe
                src={`https://info.gnv.it/repository/remotebooking/lang/img/${selectedLanguage}-login.html`}
                width="100%"
                frameBorder="0"
                title="message"
              />
            </Box>
          </Paper>
          <Paper className="adv-paper-login">
            <CustomCarousel
              locale={selectedLanguage}
              languageChangedByUser={languageChangedByUser}
            />
          </Paper>
          <FooterSection />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ExternalHomePage;
