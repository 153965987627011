import { useEffect, useState } from "react";
import RoutesEnum from "../constants/routesEnum";
import globalService from "../services/globalService";
import { AxiosResponse } from "axios";

type FeatureType = {
  name: string;
  enabled: boolean;
  description: string;
};

type GetFeaturesRes = {
  _embedded: {
    features: FeatureType[];
  };
};

export enum FeatureEnum {
  TRANSITED = "TRANSITED",
  REVERSALS = "REVERSALS",
  DISCOUNT_GRID = "DISCOUNT_GRID",
  SHIP_MAP_TRIP = "SHIP_MAP_TRIP",
  SHIP_MAP_MANAGEMENT = "SHIP_MAP_MANAGEMENT",
}

const useEnabledFeatures = (): ((featureName: FeatureEnum) => boolean) => {
  const [enabledFeatures, setEnabledFeatures] = useState<FeatureType[]>([]);
  const getEnabledFeatures = async () => {
    try {
      const res: AxiosResponse<GetFeaturesRes> = await globalService.get(
        RoutesEnum.FEATURES
      );

      setEnabledFeatures(res?.data?._embedded?.features);
    } catch (err) {
      return err;
    }
  };

  const isFeatureEnabled = (featureName: FeatureEnum): boolean => {
    let currentUrl = location.href;

    if (process.env.LOCAL_ENV === "dev" || currentUrl.includes("bo-portal-dev"))
      return enabledFeatures.find(({ name }) => name === featureName)?.enabled;
    return true;
  };

  useEffect(() => {
    //getEnabledFeatures();

    return () => {
      setEnabledFeatures([]);
    };
  }, []);

  return isFeatureEnabled;
};

export default useEnabledFeatures;
