import {
  REACT_APP_INTERNAL_PATH,
  REACT_APP_EXTERNAL_PATH,
  REACT_APP_PATH,
} from "@gnv/resevo-libportal-web";

const pathsObj = {
  INTERNAL_DEV: REACT_APP_INTERNAL_PATH,
  INTERNAL_QA: REACT_APP_INTERNAL_PATH,
  INTERNAL_PRE: REACT_APP_INTERNAL_PATH,
  INTERNAL_TEST: REACT_APP_INTERNAL_PATH,
  INTERNAL_PROD: REACT_APP_INTERNAL_PATH,
  EXTERNAL_DEV: REACT_APP_EXTERNAL_PATH,
  EXTERNAL_QA: REACT_APP_EXTERNAL_PATH,
  EXTERNAL_PRE: REACT_APP_EXTERNAL_PATH,
  EXTERNAL_TEST: REACT_APP_EXTERNAL_PATH,
  EXTERNAL_PROD: REACT_APP_EXTERNAL_PATH,
  LOCALHOST: REACT_APP_PATH,
} as const;
export default pathsObj;
