import { ComponentsState } from "piral";
import CustomLoader from "../components/customLoader/CustomLoader";
import DefaultLayout from "./default-layout/DefaultLayout";
import { Redirect } from "react-router";
import React from "react";

export const home: React.FC = () => <Redirect to="/" />;

export const layout: Partial<ComponentsState> = {
  Layout: DefaultLayout,
  LoadingIndicator: CustomLoader
};
