import { Grid } from "@mui/material";
import { useAction } from "piral-core";
import React from "react";
import CustomLoader from "../../components/customLoader/CustomLoader";
import ErrorPage404 from "../../pages/404/ErrorPage404";
import { PiralCustomState } from "piral-core/lib/types/custom";
const NotFound = () => {
  const readState = useAction("readState");
  const loadingPilets = readState((state: PiralCustomState) => {
    return state.loadingPilets;
  });
  return loadingPilets ? (
    <CustomLoader />
  ) : (
    <Grid sx={{ height: "100vh" }}>
      <ErrorPage404 />
    </Grid>
  );
};

export default NotFound;
