if (!('captureStackTrace' in Error)) {
  Error.captureStackTrace = function(targetObject, constructorOpt) {
    try {
      throw new Error();
    } catch (err) {
      const stackTrace = err.stack;

      if (typeof targetObject === 'object') {
        targetObject.stack = stackTrace;
      }

      if (typeof constructorOpt === 'function') {
        const constructorName = constructorOpt.name;
        const indexOfConstructor = stackTrace.indexOf(`at ${constructorName}`);

        if (indexOfConstructor !== -1) {
          const traceFromConstructor = stackTrace.slice(indexOfConstructor);
          targetObject.stack = traceFromConstructor;
        }
      }
    }
  };
}