import {
  createHttpBaseService,
  REACT_APP_API_URL,
} from "@gnv/resevo-libportal-web";

const baseApiUrl = REACT_APP_API_URL;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const globalService = createHttpBaseService(baseApiUrl, headers, "/logout");

export default globalService;
