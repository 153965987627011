import { Operation } from "@gnv/resevo-libportal-web";

const userHasOperation = (): ((
  operation: Operation[],
  operationsUser
) => boolean) => {
  const hasOperation = (operation: Operation[], operationsUser): boolean => {
    try {
      if (!operation) return false;

      let result = false;

      if (Array.isArray(operation)) {
        if (
          Object.entries(operationsUser || {}).some(([key]) =>
            operation.includes(key)
          )
        )
          result = true;
      } else if (operationsUser?.[operation] === "E") result = true;

      return result;
    } catch (err) {
      console.log(err);
    }
  };

  return hasOperation;
};

export default userHasOperation;
