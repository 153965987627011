import * as React from "react";
import {Grid, MenuItem} from "@mui/material";
import {Localizer} from "../../i18n/localizer";
import {CustomMuiResevoSelect} from "@gnv/resevo-react-lib";
import Typography from "@mui/material/Typography";

const LanguagePicker = ({ languages, onChangeLanguage }) => {
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    Localizer.getInstance().getCurrentLanguage
  );
  const onLanguageClick = (language) => {
    Localizer.getInstance().setCurrentLanguage(language);
    setSelectedLanguage(language);
    onChangeLanguage(language);
  };

  return (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <CustomMuiResevoSelect
        label={Localizer.getInstance().translate("language-picker-label")}
        name="language"
        value={selectedLanguage}
        onChange={(event) => {
          onLanguageClick(event.target.value);
        }}
        InputLabelProps={{ color: "secondary" }}
        sx={{ width: "356px" }}
      >
        {languages.map((lang, index: number) => {
          return (
            <MenuItem
              className="custom-drop-down-menu-item"
              key={"rate-type-menu-item" + index}
              value={lang}
            >
              <Typography variant="caption">
                {Localizer.getInstance().translate(lang)}
              </Typography>
            </MenuItem>
          );
        })}
      </CustomMuiResevoSelect>
    </Grid>
  );
};

export default LanguagePicker;
