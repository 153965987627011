import { Box, CircularProgress, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import LeftArrowIcon from "../../../static/svgs/carousel_left_arrow";
import RightArrowIcon from "../../../static/svgs/carousel_right_arrow";
import { getSlides } from "../../../utils/externalLinks";
import "./customCarousel.scss";

const CustomCarousel = ({ locale, languageChangedByUser }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [validSlides, setValidSlides] = useState([]);
  const [loading, setLoading] = useState(true);
  const selectedLanguage = locale.toUpperCase();

  const slides = useMemo(
    () => getSlides(selectedLanguage),
    [selectedLanguage, locale]
  );

  useEffect(() => {
    if (languageChangedByUser && currentSlide !== undefined) {
      setValidSlides([]);
      setCurrentSlide(0);
      setLoading(true);
    }
  }, [locale, languageChangedByUser]);

  const checkImageExists = useCallback(async (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  }, []);

  const verifySlides = useCallback(async () => {
    try {
      const results = await Promise.all(
        slides.map(async (slide) => {
          const existingImages = await checkImageExists(slide.url);
          return existingImages ? slide : null;
        })
      );
      const validImages = results.filter((slide) => slide !== null);
      setValidSlides(validImages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [slides, checkImageExists]);

  useEffect(() => {
    verifySlides();
  }, [selectedLanguage, verifySlides]);

  const changeSlide = (direction) => {
    if (validSlides.length > 0) {
      setCurrentSlide((prev) => {
        const newSlide =
          (prev + direction + validSlides.length) % validSlides.length;
        return newSlide;
      });
    }
  };

  useEffect(() => {
    let slideInterval;
    if (validSlides.length > 1) {
      slideInterval = setInterval(() => changeSlide(1), 5000);
    }
    return () => clearInterval(slideInterval);
  }, [validSlides]);

  return (
    <Box className="carousel-container">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box
            component="img"
            src={validSlides[currentSlide]?.url}
            alt={`Slide ${currentSlide + 1}`}
            className="carousel-image"
          />
          {validSlides.length > 1 && (
            <>
              <IconButton
                onClick={() => changeSlide(-1)}
                className="carousel-button left"
              >
                <LeftArrowIcon />
              </IconButton>
              <IconButton
                onClick={() => changeSlide(1)}
                className="carousel-button right"
              >
                <RightArrowIcon />
              </IconButton>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default CustomCarousel;
