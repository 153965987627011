import type { AxiosResponse } from "axios";
import {
  createHttpBaseService,
  REACT_APP_API_URL,
} from "@gnv/resevo-libportal-web";
import { LOGOUT_URL } from "../constants/appshellConstants";
import { Tree } from "../components/userProfileModal/fileExplorerSection/types";
import { Localizer } from "../i18n/localizer";

const treeServiceUrl = `${REACT_APP_API_URL}`;

const baseService = createHttpBaseService(treeServiceUrl, null, LOGOUT_URL);

const fileService = {
  ...baseService,
  getTreeData: async (nodeId?: string): Promise<AxiosResponse<Tree>> => {
    if (!nodeId) {
      return baseService.get("/user/agency-documents");
    }
    return baseService.get(`/user/agency-documents/${nodeId}`);
  },
  downloadFile: async (nodeId: string, path: string) => {
    const fileName = path.split("/")[path.split("/").length - 1];
    const fileExtension = path
      .split("/")
      [path.split("/").length - 1].split(".")[
      path.split("/")[path.split("/").length - 1].split(".").length - 1
    ];

    try {
      const { data } = await baseService.get<any>(
        `/user/agency-documents/download/${nodeId}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: `application/${fileExtension}`,
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (e) {
      let errorMsg = "error.server_error";
      if (e.response) {
        switch (e.response.status) {
          case 500:
            errorMsg = "FCE_E0322";
            break;
          case 404:
            errorMsg = "FCE_E0321";
            break;
          default:
            break;
        }
      }
      const translatedError = Localizer.getInstance().translate(errorMsg);
      console.error(translatedError);
      throw new Error(translatedError);
    }
  },
};

export default fileService;
