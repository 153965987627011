import {
  REACT_APP_GNV_PREFIX_URL,
  REACT_APP_GNV_OAUTH_AUTHORIZE_URL,
  REACT_APP_ADV_PREFIX_URL,
  REACT_APP_ADV_OAUTH_AUTHORIZE_URL,
} from "@gnv/resevo-libportal-web";
function getLoginUrl() {
  let currentUrl = location.href;

  if (currentUrl.indexOf(REACT_APP_GNV_PREFIX_URL) != -1) {
    return REACT_APP_GNV_OAUTH_AUTHORIZE_URL;
  } else if (currentUrl.indexOf(REACT_APP_ADV_PREFIX_URL) != -1) {
    return REACT_APP_ADV_OAUTH_AUTHORIZE_URL;
  } else {
    return null;
  }
}

export default getLoginUrl;
