import React from "react";
import pathsObj from "../../constants/pathsObj";
import ExternalHomePage from "../../pages/externalHomePage/ExternalHomePage";
import InternalHomePage from "../../pages/internalHomePage/InternalHomePage";

interface HomePageUIProps {
  pathType: PathsObj;
}

export type PathsObj = (typeof pathsObj)[keyof typeof pathsObj];

const HomePageUI: React.FC<HomePageUIProps> = ({ pathType }) => {
  const homePageUI: Record<PathsObj, JSX.Element> = {
    [pathsObj.INTERNAL_DEV]: <InternalHomePage />,
    [pathsObj.INTERNAL_QA]: <InternalHomePage />,
    [pathsObj.INTERNAL_TEST]: <InternalHomePage />,
    [pathsObj.INTERNAL_PRE]: <InternalHomePage />,
    [pathsObj.INTERNAL_PROD]: <InternalHomePage />,
    [pathsObj.EXTERNAL_DEV]: <ExternalHomePage />,
    [pathsObj.EXTERNAL_QA]: <ExternalHomePage />,
    [pathsObj.EXTERNAL_TEST]: <ExternalHomePage />,
    [pathsObj.EXTERNAL_PRE]: <ExternalHomePage />,
    [pathsObj.EXTERNAL_PROD]: <ExternalHomePage />,
    [pathsObj.LOCALHOST]: <ExternalHomePage />,
  };
  return homePageUI[pathType];
};

export default HomePageUI;
