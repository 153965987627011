import { AxiosError, AxiosResponse } from "axios";
import RoutesEnum from "../constants/routesEnum";
import { createHttpBaseService, REACT_APP_API_URL } from "@gnv/resevo-libportal-web";
import { LOGOUT_URL } from "../constants/appshellConstants";

const base = REACT_APP_API_URL;
const baseService = createHttpBaseService(base, null, LOGOUT_URL);

const getPrepaidAgencyInfo = (
  agencyCode: string,
  successCallback?: (response: AxiosResponse) => void,
  errorCallback?: (error?: AxiosError) => void
) => {
  baseService
    .get(`${RoutesEnum.PREPAID_AGENCY_INFO}${agencyCode}`)
    .then(response => {
      if (successCallback) successCallback(response);
    })
    .catch(error => {
      if (errorCallback) errorCallback(error);
    });
};

export default getPrepaidAgencyInfo;