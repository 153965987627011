import { Localizable, setupLocalizer } from "piral";
import { translations } from "./translations";
import Cookies from "js-cookie";
import { AppshellUtil } from "../utils/appshellUtil";
import {
  commonTranslations,
  getBrowserLanguage,
  mergeDeep,
} from "@gnv/resevo-libportal-web";
import { RESEVO_LOCALE_COOKIE_NAME } from "../constants/appshellConstants";
import { deDE, enUS, esES, frFR, itIT } from "@mui/x-data-grid";

export class Localizer {
  private DEFAULT_LANGUAGE = getBrowserLanguage(
    Object.keys(commonTranslations)
  );

  private COOKIE_LANGUAGE_NAME = RESEVO_LOCALE_COOKIE_NAME;
  private readonly LOCALES = {
    itIT: itIT.components.MuiDataGrid.defaultProps.localeText,
    enUS: enUS.components.MuiDataGrid.defaultProps.localeText,
    frFR: frFR.components.MuiDataGrid.defaultProps.localeText,
    esES: esES.components.MuiDataGrid.defaultProps.localeText,
    deDE: deDE.components.MuiDataGrid.defaultProps.localeText,
  };

  private static _instance: Localizer;
  private readonly localizer: Localizable;

  private constructor() {
    const mergedTranslations = mergeDeep(commonTranslations, translations);
    this.localizer = setupLocalizer({
      language: this.getCurrentLanguage(),
      messages: mergedTranslations,
    });
  }

  public static getInstance(): Localizer {
    return this._instance || (this._instance = new this());
  }

  public getCurrentLanguage = () => {
    return !!Cookies.get(this.COOKIE_LANGUAGE_NAME)
      ? Cookies.get(this.COOKIE_LANGUAGE_NAME)
      : this.DEFAULT_LANGUAGE;
  };

  public getGridLanguage() {
    return this.LOCALES[this.getCurrentLanguage()];
  }

  public setCurrentLanguage = (language): void => {
    AppshellUtil.getInstance()
      .getAppshellInstance()
      .context.selectLanguage(language);
    Cookies.set(this.COOKIE_LANGUAGE_NAME, language, { expires: 30 });
  };

  public getLocalizable = (): Localizable => {
    return this.localizer;
  };

  public translate = (key): string => {
    let result: string;
    try {
      result = AppshellUtil.getInstance().getAppshellApi().translate(key);
    } catch (err) {
      result = this.localizer.localizeGlobal(key);
    }
    return result;
  };
}
