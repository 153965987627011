import { AGENCY_TYPES, IUserData } from "@gnv/resevo-react-lib";
import LoginIcon from "@mui/icons-material/Login";
import { Avatar, IconButton, ListItemText, Typography, Grid } from "@mui/material";
import React from "react";
import RemainingCredit from "../../remainingCredit/RemainingCredit";

type IUserAvatarProps = {
  handleOpenModalUser?: () => void;
  currentUser: IUserData;
  open: boolean;
  logout: () => void;
  userProfileModal?: boolean;
};

function UserAvatar({
  handleOpenModalUser,
  open,
  currentUser,
  logout,
  userProfileModal,
}: IUserAvatarProps) {
  const isAgencyPrepaid = currentUser?.userAccountAgency?.ticketOfficeTypeCode === AGENCY_TYPES.PREPAID_GNV;
  const displayRemainingCredit = isAgencyPrepaid && open && !userProfileModal;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: "8px",
        paddingBottom: "8px",
        overflowY: "hidden",
      }}
      onClick={() => handleOpenModalUser && handleOpenModalUser()}
    >
      <Avatar
        sx={{
          bgcolor: userProfileModal ? "#0047BA" : "white",
          width: 40,
          height: 40,
          p: 2,
          cursor: "pointer",
          mr: open ? 1.5 : 0,
          lineHeight: "0px",
        }}
      >
        <Typography
          style={{
            fontSize: "12px",
            fontWeight: 700,
            letterSpacing: "0px",
            color: userProfileModal ? "#fff" : "#0047BA",
          }}
          color="primary"
          variant="caption"
        >
          {currentUser?.username?.charAt(0)?.toUpperCase()}
        </Typography>
      </Avatar>

      <Grid style={{
        display: displayRemainingCredit ? "flex" : "contents",
        flexDirection: "column",
      }}>
        {(open || userProfileModal) && (
          <ListItemText
            sx={{
              ml: "5px",
            }}
            primaryTypographyProps={{
              fontStyle: "normal",
              fontSize: "12px",
              lineHeight: "150%",
              letterSpacing: "0.15px",
              color: userProfileModal ? "#0047BA" : "white",
              fontWeight: "500",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={currentUser?.name}
            primary={currentUser?.name}
          />
        )}
        {userProfileModal && (
          <IconButton
            sx={{
              m: "5px",
            }}
            onClick={logout}
          >
            <LoginIcon />
          </IconButton>
        )}

        {displayRemainingCredit &&
          <RemainingCredit
            agencyCode={currentUser?.userAccountAgency?.ageCode}
          />
        }
      </Grid>
    </div>
  );
}

export default UserAvatar;
