import {
  UserProfile,
  createHttpBaseService,
  REACT_APP_API_URL,
} from "@gnv/resevo-libportal-web";
import { LOGOUT_URL } from "../constants/appshellConstants";
import type { AxiosResponse } from "axios";
const userServiceUrl = REACT_APP_API_URL + "/user/users/profile";

const baseService = createHttpBaseService(userServiceUrl, null, LOGOUT_URL);

const userService = {
  ...baseService,
  getUserProfile: async (): Promise<AxiosResponse<UserProfile>> =>
    baseService.get("/"),
};

export default userService;

// class UserService extends HttpBaseService{
//     constructor(baseApiUrl) {
//         super(baseApiUrl, null, LOGOUT_URL);
//     }

//     public async getUserProfile(): Promise<AxiosResponse<UserProfile>> {
//         return this.get('/');
//     }
// }

// export default new UserService(userServiceUrl);
