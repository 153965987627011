import React from "react";
import { Typography } from "@mui/material";
import { Button } from "@gnv/resevo-react-lib";
import getLoginUrl from "../../utils/loginUtils";
import "./login.scss";
import { Localizer } from "../../i18n/localizer";

interface LoginProps {
  logo: string;
  height: string;
  width: string;
  loginTitle: string;
}

const Login: React.FC<LoginProps> = ({ logo, height, width, loginTitle }) => {
  return (
    <>
      <img
        className="resevo-logo"
        src={logo}
        width={width}
        height={height}
        alt="resevo logo blue"
      />
      <div style={{ width: "100%" }}>
        <Typography className="login-typography" variant="h1" gutterBottom>
          {loginTitle}
        </Typography>
        <Button
          className="login-button"
          variant="contained"
          color="primary"
          href={getLoginUrl()}
        >
          {Localizer.getInstance().translate("login-button")}
        </Button>
        <Typography
          className="login-typography-body2"
          variant="body2"
          gutterBottom
        >
          {Localizer.getInstance().translate("login-info")}
        </Typography>
      </div>
    </>
  );
};

export default Login;
