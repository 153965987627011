import React from "react";

const RightArrowIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 15C3 8.385 8.385 3 15 3C21.615 3 27 8.385 27 15C27 21.615 21.615 27 15 27C8.385 27 3 21.615 3 15ZM-6.55671e-07 15C-1.0176e-06 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 -2.93741e-07 15 -6.55671e-07C6.72 -1.0176e-06 -2.93741e-07 6.72 -6.55671e-07 15ZM15 13.5L9 13.5L9 16.5L15 16.5L15 21L21 15L15 9L15 13.5Z"
        fill="black"
        fill-opacity="0.54"
      />
    </svg>
  );
};
export default RightArrowIcon;
