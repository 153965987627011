import { Grid, Typography } from "@mui/material";
import React from "react";
import { Localizer } from "../../../i18n/localizer";
import { footerLinks } from "../../../utils/externalLinks";

const FooterSection: React.FC = () => {
  const localizer = Localizer.getInstance();

  return (
    <div className="footer-section">
      <Grid container>
        <Grid item xs={12} sm={3} className="footer-first-section">
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {localizer.translate("footer-business-name")}
          </Typography>
          <Typography variant="body2">
            {localizer.translate("footer-vat-number")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} className="footer-second-section">
          {footerLinks.map((link, index) => (
            <React.Fragment key={index}>
              <a
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                <Typography variant="body2" display="inline">
                  {localizer.translate(link.textKey)}
                  {index < footerLinks.length - 1 && " | "}
                </Typography>
              </a>
              {index === 4 && <br />}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default FooterSection;
