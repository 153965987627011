import React from "react";

const LeftArrowIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 15C27 21.615 21.615 27 15 27C8.385 27 3 21.615 3 15C3 8.385 8.385 3 15 3C21.615 3 27 8.385 27 15ZM30 15C30 6.72 23.28 2.77611e-07 15 1.78873e-07C6.72 8.01352e-08 2.77611e-07 6.72 1.78873e-07 15C8.01352e-08 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15ZM15 16.5L21 16.5L21 13.5L15 13.5L15 9L9 15L15 21L15 16.5Z"
        fill="black"
        fill-opacity="0.54"
      />
    </svg>
  );
};
export default LeftArrowIcon;
