import Box from "@mui/material/Box";
import LoaderGnv from "../../static/images/logo-loader.svg";
import {Typography} from "@mui/material";
import {Localizer} from "../../i18n/localizer";
import "./customLoader.scss";
import * as React from "react";

const CustomLoader = () => {
  return (
    <Box className={"custom-loader"}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        rowGap={1.5}
      >
        <Box
          component="img"
          src={LoaderGnv}
          alt="loader-gnv"
          width="174px"
          height="87px"
          className="loader-gnv"
        />
        <Typography variant="h2" align="center" color="#0047BA" fontSize="28px">
          {Localizer.getInstance().translate("loading")}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomLoader;
