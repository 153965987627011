
import { Typography, Grid } from "@mui/material";
import React from "react";
import { AxiosError, AxiosResponse } from "axios";
import { AgencyCreditLeftType, Logger, formatCurrency } from "@gnv/resevo-react-lib";
import "./remainingCredit.scss";
import { Localizer } from "../../i18n/localizer";
import getPrepaidAgencyInfo from "../../services/PrepaidAgencyService";

type IRemainingCreditProps = {
    agencyCode: string
};

function RemainingCredit({
    agencyCode
}: IRemainingCreditProps) {
    const [prepaidAgencyInfo, setPrepaidAgencyInfo] = React.useState<AgencyCreditLeftType>(null);
    const prepaidBalance = formatCurrency(prepaidAgencyInfo?.prepaidBalance);

    React.useEffect(() => {
        getPrepaidAgencyInfo(
            agencyCode,
            (response: AxiosResponse) => setPrepaidAgencyInfo(response?.data),
            (error: AxiosError) => Logger.log(error));
    }, []);

    return (
        <Grid sx={{ ml: "5px" }}>
            <Typography
                className="remaining-credit-value"
                color="primary"
                variant="caption"
            >
                {`${Localizer.getInstance().translate("remaining-credit")} ${prepaidAgencyInfo?.currencyCode || ""} ${prepaidBalance}`}
            </Typography>
        </Grid>
    );
}

export default RemainingCredit;

